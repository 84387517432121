/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../LCFQheader";
import MytableRadio from "../../common/hksqRadio/MytableRadio";
import { RxUtil } from "@/assets/util.js";
import { timesFnt, convertKey, format, unique, convertCurrency } from "@/assets/app.js";
export default {
  name: "lcfqHtsp",
  components: {
    LCFQheader,
    MytableRadio
  },
  data() {
    return {
      showGroupDialog: false,
      allowSelect: true,
      nodeGroupVm: {},
      //--------------------
      oneft: "/index",
      headTit: "新建申请",
      tit: "合同审批",
      //下拉
      showHTSP: false,
      showJSFS: false,
      columnsHTSP: [],
      columnsJSFS: [],
      showHkrq: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      showGinfo: false,
      //userSelect
      single: false,
      allowSelect: true,
      showUserDialog: false,
      userID: "",
      solId: "",
      boDefId: "",
      formKey: "",
      actDefId: "",
      resultFa: [],
      wpInfoData: {},
      data: {
        ks_name: "",
        jbr: "",
        fj: []
      },
      fjsc: [],
      zffs: "",
      jedx: ""
    };
  },
  created() {
    this.initBySolInstId();
    this.cgORxj();
    this.getArr();
  },
  methods: {
    initBySolInstId() {
      let instId = this.$route.params.instId;
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(res => {
        this.solId = res.data.bpmSolution.solId;
        this.boDefId = res.data.formModels[0].boDefId;
        this.formKey = res.data.formModels[0].formKey;
        if (instId == 0) {
          let ks_name = [{
            id: res.data.formModels[0].jsonData.ks,
            text: res.data.formModels[0].jsonData.ks_name
          }];
          this.data.ks_name = JSON.stringify(ks_name);
        }
      });
    },
    async cgORxj() {
      //let busad = this.$route.params.busad;
      let instId = this.$route.params.instId;
      if (instId != 0) {
        //if (typeof(busad) != "undefined"){
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        /**测试接口**/
        var user_No = RxUtil.getCache("userNo"); //这里要重新储存
        console.log(user_No);
        var url2 = _baseUrl + "/mobile/bpm/getFormDataByInstId.do?instId=" + instId + "&userAccount=" + user_No; //+"&instId="+instId
        var this_ = this;
        this.$ajax.post(url2).then(response => {
          var resData = response.data.data.bos[0].data;
          console.log("草稿数据返填=", resData);
          let ks_name = [{
            id: resData.ks,
            text: resData.ks_name
          }];
          this_.data.ks_name = JSON.stringify(ks_name);
          this_.data.htlx = resData.htlx;
          this_.data.htjedx = resData.htjedx;
          this_.data.htjewy = resData.htjewy;
          this_.data.htmc = resData.htmc;
          this_.data.jbr = resData.jbr;
          this_.data.qdfs = resData.qdfs;
          this_.data.qdsy = resData.qdsy;
          this_.data.xmmc = resData.xmmc;
          this_.data.yf = resData.yf;
          this_.data.zynr = resData.zynr;
          this_.data.jf = resData.jf;
          this_.data.bf = resData.bf;

          // fj: "5" //附件
        });
        /***/
        //     var url = _baseUrl + "/customizeProc/purRequisition/purRequisition/getJson.do?ids="+busad;
        // this.$ajax.post(url).then(res=>{
        // 	this.data = res.data;
        //       let urlUser1 = _baseUrl + `/mobile/oa/getUsersSearch.do?pageIndex=0&pageSize=20&searchVal=${dlrseach}`;
        //       this.$ajax.post(urlUser1).then(resdlr=>{
        //       let dlr = [{id:resdlr.data.rowList[0].userId,text:resdlr.data.rowList[0].fullname}];
        //         this.data.dlrName = JSON.stringify(dlr);
        //       })
        // });
      } else {
        this.getUser();
      }
    },
    //获取用户信息
    getUser() {
      this.data.jbr = RxUtil.getCache("userName");
    },
    getArr() {
      let urlhtlx = _baseUrl + "/sys/core/sysDic/listByKey.do?key=HTFL&_=1639983893903";
      this.$ajax.post(urlhtlx).then(res => {
        let columnsHTSP = [];
        res.data.map(item => {
          columnsHTSP.push(item.name);
        });
        this.columnsHTSP = columnsHTSP;
      });
      let urljsfs = _baseUrl + "/sys/core/sysDic/listByKey.do?key=HTGL_HTFKFS&_=1640337311917";
      var columnsHTSP = [];
      this.$ajax.post(urljsfs).then(res => {
        let columnsJSFS = [];
        res.data.map(item => {
          columnsJSFS.push(item.name);
        });
        this.columnsJSFS = columnsJSFS;
      });
    },
    onHtjedx() {
      this.data.htjedx = convertCurrency(this.data.htjewy);
    },
    //科室
    selectGroupDialog(vm) {
      this.showGroupDialog = true;
      this.$refs.sysGroup.type = "group";
      this.$refs.sysGroup.search();
      // 设置选择用户。
      this.$refs.sysGroup.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeGroupVm = vm;
    },
    selectGroup(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        var test = {};
        o.id = obj.groupId;
        o.text = obj.name;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeGroupVm.setValue(json);
      this.showGroupDialog = false;
    },
    onConfiHTSP(value) {
      this.data.htlx = value;
      this.showHTSP = false;
    },
    onConfiJSFS(value) {
      this.data.qdfs = value;
      this.showJSFS = false;
    },
    //上传附件
    beforeRead(file) {
      console.log(this.data.fj);
      if (this.data.fj.length > 0) {
        let fjStr = this.onFile();
        if (fjStr.indexOf(file.name) == -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    afterRead(file) {
      let formData = new FormData();
      formData.append("from", "SELF");
      formData.append("types", "");
      formData.append("sysTreeId", "");
      formData.append("id", "WU_FILE_0");
      formData.append("name", file.file.name);
      formData.append("type", file.file.type);
      formData.append("lastModifiedDate", file.file.lastModifiedDate);
      formData.append("size", file.file.size);
      formData.append("file", file.file);
      var fjObj;
      let formDataUrl = _baseUrl + "/sys/core/file/upload.do";
      this.$ajax.post(formDataUrl, formData).then(res => {
        fjObj = {};
        fjObj.fileId = res.data.data[0].fileId;
        fjObj.fileName = res.data.data[0].fileName;
        fjObj.totalBytes = res.data.data[0].totalBytes;
        this.fjsc.push(fjObj);
      });
    },
    beforeDelete(file) {
      console.log(this.data.fj);
      if (this.data.fj.length > 1) {
        for (let i = 0; i < this.fjsc.length; i++) {
          if (file.file.name == this.fj[i].fileName) {
            this.fj.splice(i, 1);
          }
        }
        return true;
      } else {
        return true;
      }
    },
    onFile() {
      let fjObj = {};
      let fj = [];
      this.data.fj.map(item => {
        fjObj = {};
        fjObj.fileId = item.file.lastModified;
        fjObj.fileName = item.file.name;
        fjObj.totalBytes = item.file.size;
        fj.push(fjObj);
      });
      return JSON.stringify(fj);
    },
    datas() {
      let fj = JSON.stringify(this.fjsc);
      let ks = JSON.parse(this.data.ks_name);
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            ks: ks[0].id,
            ks_name: ks[0].text,
            htlx: this.data.htlx,
            htlx_name: this.data.htlx,
            htmc: this.data.htmc,
            xmmc: this.data.xmmc,
            qdsy: this.data.qdsy,
            zynr: this.data.zynr,
            qysw: this.data.qysw,
            jf: this.data.jf,
            yf: this.data.yf,
            bf: this.data.bf,
            jbr: this.data.jbr,
            htjewy: this.data.htjewy,
            htjedx: this.data.htjedx,
            qdfs: this.data.qdfs,
            qdfs_name: this.data.qdfs_name,
            fj: fj
          }
        }]
      };
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("jsonData", jsonData);
      return params;
    },
    //字段检查
    iN(str) {
      if (str == '' || str == null || typeof str == 'undefined') {
        return true;
      }
      return false;
    },
    //整体判断
    inspect() {
      if (this.iN(this.data.ks_name) || this.iN(this.data.htmc) || this.iN(this.data.xmmc) || this.iN(this.data.jf) || this.iN(this.data.yf) || this.iN(this.data.htjewy) || this.iN(this.data.qdfs)) {
        return true;
      }
      return false;
    },
    onSubmit() {
      if (this.inspect()) {
        this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！" + data.data, "1800");
        }
      });
    },
    onPreservation() {
      if (this.inspect()) {
        this.errorToast("保存失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败！" + data.data, "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};